import styles from "../../pages/components/registerForm/registerForm.module.less";
import {Checkbox} from "antd";
import React, {ReactNode, useState} from "react";

export default function CustomCheckbox(props: CustomCheckboxProps) {
    const [value, setValue] = useState<boolean>(!!props.value);

    const onClick = () => {
        props.onChange(!value);
        setValue(!value);
    }

    return props.order === "textFirst" ?
        <>
            <div className={props.wrapperClassName ? props.wrapperClassName : styles.checkboxWrapper}>
                <a onClick={onClick}>
                    <div className={props.textStyles ? props.textStyles : styles.checkboxText}>{props.text}</div>
                </a>
                <Checkbox onClick={onClick} className={styles.checkbox} value={value} checked={value}
                          defaultChecked={props.defaultValue}/>
            </div>
            {props.children}
        </>
        :
        <>
            <div className={props.wrapperClassName ? props.wrapperClassName : styles.checkboxWrapper}>
                <Checkbox onClick={onClick} className={styles.checkbox} value={value} checked={value}
                          defaultChecked={props.defaultValue}/>
                <a onClick={onClick}>
                    <div className={props.textStyles ? props.textStyles : styles.checkboxText}>{props.text}</div>
                </a>
            </div>
            {props.children}
        </>
}

interface CustomCheckboxProps {
    text: ReactNode,
    onChange?: any,
    value?: any,
    defaultValue?: any,
    textStyles?: any,
    wrapperClassName?: any,
    order?: "textFirst",
    children?: ReactNode
}
