import React, {Suspense} from "react";
import { ApplicationUserRole } from "../../api";
import {loadingSpin} from "./routingContainer";
import {AuthInitialized, Protected} from "./routingWrappers";

const Home = React.lazy(() => import("../home/home"));
const Main = React.lazy(() => import("../components/mainPage"));
const Login = React.lazy(() => import( "../loginPage/loginPage"));
const ChangePassword = React.lazy(() => import( "../changePasswordPage/changePasswordPage"));
const Register = React.lazy(() => import( "../registerPage/registerPage"));
const Users = React.lazy(() => import("../applicationUser/applicationUserListContainer"));

const ForgotPassword = React.lazy(() => import("../components/forgotPassword"));
const ForgotPasswordComplete = React.lazy(() => import("../components/forgotPasswordComplete"));
const Activate = React.lazy(() => import("../authentication/activate/activatePage"));
const Confirm = React.lazy(() => import("../authentication/activate/confirmPage"));
const Privacy = React.lazy(() => import("../privacy"));

export const LoginPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Login/>
</AuthInitialized>;

export const ChangePasswordPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <ChangePassword/>
</AuthInitialized>;

export const RegisterPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Register/>
</AuthInitialized>;

export const ForgotPasswordPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <ForgotPassword/>
</AuthInitialized>;

export const ForgotPasswordCompletePage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <ForgotPasswordComplete/>
</AuthInitialized>

export const ActivatePage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Activate/>
</AuthInitialized>;

export const ConfirmPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Confirm/>
</AuthInitialized>;

export const UsersPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <Users/>
</Protected>

export const MainPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <Main/>
</Protected>

export const HomePage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Home/>
</AuthInitialized>;

const InstitutionTableContainer = React.lazy(() => import("../institution/institutionListContainer"));

export const InstitutionPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <InstitutionTableContainer/>
</Protected>

const InstitutionFormContainer = React.lazy(() => import("../institution/institutionForm/institutionFormContainer"));

export const InstitutionFormPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <InstitutionFormContainer/>
</Protected>

const ZoneTableContainer = React.lazy(() => import("../zone/zoneListContainer"));

export const ZonePage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <ZoneTableContainer/>
</Protected>

const ZoneFormContainer = React.lazy(() => import("../zone/zoneForm/zoneFormContainer"));

export const ZoneFormPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <ZoneFormContainer/>
</Protected>

const StandTableContainer = React.lazy(() => import("../stand/standListContainer"));

export const StandPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <StandTableContainer/>
</Protected>

const StandFormContainer = React.lazy(() => import("../stand/standForm/standFormContainer"));

export const StandFormPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <StandFormContainer/>
</Protected>

const DemonstrationTableContainer = React.lazy(() => import("../demonstration/demonstrationListContainer"));

export const DemonstrationPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <DemonstrationTableContainer/>
</Protected>

const DemonstrationFormContainer = React.lazy(() => import("../demonstration/demonstrationForm/demonstrationFormContainer"));

export const DemonstrationFormPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <DemonstrationFormContainer/>
</Protected>

const Favourites = React.lazy(() => import("../favourites/favourites"));

export const FavouritesPage = () => <Protected loadingIndicator={loadingSpin}>
    <Favourites/>
</Protected>

const AllStands = React.lazy(() => import("../allStands/allStands"));

export const AllStandsPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <AllStands/>
</AuthInitialized>

const YouAreHereTableContainer = React.lazy(() => import("../youAreHere/youAreHereListContainer"));

export const YouAreHerePage = () => <Protected loadingIndicator={loadingSpin}>
    <YouAreHereTableContainer />
</Protected>

const YouAreHereFormContainer = React.lazy(() => import("../youAreHere/youAreHereForm/youAreHereFormContainer"));

export const YouAreHereFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <YouAreHereFormContainer />
</Protected>

export const PrivacyPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Privacy />
</AuthInitialized>