import {createSlice} from "@reduxjs/toolkit";

const initialState: {
    globalLanguage: string
} = {
    globalLanguage: localStorage.getItem("language") || "pl-PL",
};

const languageSlice = createSlice({
    name: "languageSlice",
    initialState,
    reducers: {
        setGlobalLanguage: (state, action) => {
            localStorage.setItem("language", action.payload);
            state.globalLanguage = action.payload;
        }
    }
});

export const {setGlobalLanguage} = languageSlice.actions;

export const getGlobalLanguage = (state: any) => state.language.globalLanguage as "pl-PL" | "uk-UA";

export default languageSlice.reducer;
