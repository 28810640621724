/* tslint:disable */
/* eslint-disable */
/**
 * Digimuth.CnKopernikMap.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApplicationUserActivationDto
 */
export interface ApplicationUserActivationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserChangePasswordDto
 */
export interface ApplicationUserChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserChangePasswordDto
     */
    currentPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserChangePasswordDto
     */
    newPassword?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDetailsDto
 */
export interface ApplicationUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    termsAndConditionsAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    receiveNewsletter?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserDetailsDto
     */
    roles?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {Array<StandDto>}
     * @memberof ApplicationUserDetailsDto
     */
    stands?: Array<StandDto> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDto
 */
export interface ApplicationUserDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    termsAndConditionsAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    receiveNewsletter?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserDto
     */
    roles?: Array<ApplicationUserRole> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserRegisterDto
 */
export interface ApplicationUserRegisterDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    withInvitation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    termsAndConditionsAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    receiveNewsletter?: boolean;
    /**
     * 
     * @type {ApplicationUserRole}
     * @memberof ApplicationUserRegisterDto
     */
    role?: ApplicationUserRole;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationUserRole {
    Admin = 'Admin'
}

/**
 * 
 * @export
 * @interface ApplicationUserUpdateConsentsDto
 */
export interface ApplicationUserUpdateConsentsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserUpdateConsentsDto
     */
    isNewsletterAgreed?: boolean;
}
/**
 * 
 * @export
 * @interface DemonstrationCreateDto
 */
export interface DemonstrationCreateDto {
    /**
     * 
     * @type {number}
     * @memberof DemonstrationCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationCreateDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof DemonstrationCreateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof DemonstrationCreateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationCreateDto
     */
    standId?: number;
    /**
     * 
     * @type {StandReducedDto}
     * @memberof DemonstrationCreateDto
     */
    stand?: StandReducedDto;
}
/**
 * 
 * @export
 * @interface DemonstrationDetailsDto
 */
export interface DemonstrationDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof DemonstrationDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationDetailsDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof DemonstrationDetailsDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof DemonstrationDetailsDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationDetailsDto
     */
    standId?: number;
    /**
     * 
     * @type {StandReducedDto}
     * @memberof DemonstrationDetailsDto
     */
    stand?: StandReducedDto;
}
/**
 * 
 * @export
 * @interface DemonstrationDto
 */
export interface DemonstrationDto {
    /**
     * 
     * @type {number}
     * @memberof DemonstrationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof DemonstrationDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof DemonstrationDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationDto
     */
    standId?: number;
    /**
     * 
     * @type {StandReducedDto}
     * @memberof DemonstrationDto
     */
    stand?: StandReducedDto;
}
/**
 * 
 * @export
 * @interface DemonstrationUpdateDto
 */
export interface DemonstrationUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof DemonstrationUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemonstrationUpdateDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof DemonstrationUpdateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof DemonstrationUpdateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof DemonstrationUpdateDto
     */
    standId?: number;
    /**
     * 
     * @type {StandReducedDto}
     * @memberof DemonstrationUpdateDto
     */
    stand?: StandReducedDto;
}
/**
 * 
 * @export
 * @interface EndForgotPasswordDto
 */
export interface EndForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    newPassword?: string | null;
}
/**
 * 
 * @export
 * @interface ImageDetailsDto
 */
export interface ImageDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ImageDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ImageDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageDetailsDto
     */
    contentType?: string | null;
}
/**
 * 
 * @export
 * @interface ImageDto
 */
export interface ImageDto {
    /**
     * 
     * @type {number}
     * @memberof ImageDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    fileName?: string | null;
}
/**
 * 
 * @export
 * @interface ImageUpdateDto
 */
export interface ImageUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ImageUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ImageUpdateDto
     */
    fileName?: string | null;
}
/**
 * 
 * @export
 * @interface InstitutionCreateDto
 */
export interface InstitutionCreateDto {
    /**
     * 
     * @type {number}
     * @memberof InstitutionCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InstitutionCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InstitutionCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InstitutionCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InstitutionCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionCreateDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof InstitutionCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InstitutionCreateDto
     */
    imageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof InstitutionCreateDto
     */
    image?: ImageDto;
}
/**
 * 
 * @export
 * @interface InstitutionDetailsDto
 */
export interface InstitutionDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof InstitutionDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InstitutionDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InstitutionDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InstitutionDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InstitutionDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionDetailsDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof InstitutionDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InstitutionDetailsDto
     */
    imageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof InstitutionDetailsDto
     */
    image?: ImageDto;
}
/**
 * 
 * @export
 * @interface InstitutionDto
 */
export interface InstitutionDto {
    /**
     * 
     * @type {number}
     * @memberof InstitutionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InstitutionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InstitutionDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InstitutionDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InstitutionDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof InstitutionDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InstitutionDto
     */
    imageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof InstitutionDto
     */
    image?: ImageDto;
}
/**
 * 
 * @export
 * @interface InstitutionUpdateDto
 */
export interface InstitutionUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof InstitutionUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InstitutionUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InstitutionUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InstitutionUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InstitutionUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionUpdateDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof InstitutionUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InstitutionUpdateDto
     */
    imageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof InstitutionUpdateDto
     */
    image?: ImageDto;
}
/**
 * 
 * @export
 * @interface LocalizableStringDto
 */
export interface LocalizableStringDto {
    /**
     * 
     * @type {string}
     * @memberof LocalizableStringDto
     */
    locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocalizableStringDto
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoginDto
     */
    rememberMe?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationType {
    Add = 'Add',
    Remove = 'Remove',
    Replace = 'Replace',
    Move = 'Move',
    Copy = 'Copy',
    Test = 'Test',
    Invalid = 'Invalid'
}

/**
 * 
 * @export
 * @interface StandCreateDto
 */
export interface StandCreateDto {
    /**
     * 
     * @type {number}
     * @memberof StandCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StandCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StandCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StandCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StandCreateDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandCreateDto
     */
    institutionId?: number | null;
    /**
     * 
     * @type {InstitutionDto}
     * @memberof StandCreateDto
     */
    institution?: InstitutionDto;
    /**
     * 
     * @type {number}
     * @memberof StandCreateDto
     */
    zoneId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StandCreateDto
     */
    standNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof StandCreateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<DemonstrationDto>}
     * @memberof StandCreateDto
     */
    demonstrations?: Array<DemonstrationDto> | null;
}
/**
 * 
 * @export
 * @interface StandDetailsDto
 */
export interface StandDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof StandDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StandDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StandDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StandDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StandDetailsDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandDetailsDto
     */
    institutionId?: number | null;
    /**
     * 
     * @type {InstitutionDto}
     * @memberof StandDetailsDto
     */
    institution?: InstitutionDto;
    /**
     * 
     * @type {number}
     * @memberof StandDetailsDto
     */
    zoneId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StandDetailsDto
     */
    standNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof StandDetailsDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<DemonstrationDto>}
     * @memberof StandDetailsDto
     */
    demonstrations?: Array<DemonstrationDto> | null;
}
/**
 * 
 * @export
 * @interface StandDto
 */
export interface StandDto {
    /**
     * 
     * @type {number}
     * @memberof StandDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StandDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StandDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StandDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StandDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandDto
     */
    institutionId?: number | null;
    /**
     * 
     * @type {InstitutionDto}
     * @memberof StandDto
     */
    institution?: InstitutionDto;
    /**
     * 
     * @type {number}
     * @memberof StandDto
     */
    zoneId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StandDto
     */
    standNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof StandDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<DemonstrationDto>}
     * @memberof StandDto
     */
    demonstrations?: Array<DemonstrationDto> | null;
}
/**
 * 
 * @export
 * @interface StandReducedDto
 */
export interface StandReducedDto {
    /**
     * 
     * @type {number}
     * @memberof StandReducedDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StandReducedDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StandReducedDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StandReducedDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandReducedDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StandReducedDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandReducedDto
     */
    institutionId?: number | null;
    /**
     * 
     * @type {InstitutionDto}
     * @memberof StandReducedDto
     */
    institution?: InstitutionDto;
    /**
     * 
     * @type {number}
     * @memberof StandReducedDto
     */
    zoneId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StandReducedDto
     */
    standNumber?: string | null;
}
/**
 * 
 * @export
 * @interface StandUpdateDto
 */
export interface StandUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof StandUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StandUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StandUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StandUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StandUpdateDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandUpdateDto
     */
    institutionId?: number | null;
    /**
     * 
     * @type {InstitutionDto}
     * @memberof StandUpdateDto
     */
    institution?: InstitutionDto;
    /**
     * 
     * @type {number}
     * @memberof StandUpdateDto
     */
    zoneId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StandUpdateDto
     */
    standNumber?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof StandUpdateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<DemonstrationDto>}
     * @memberof StandUpdateDto
     */
    demonstrations?: Array<DemonstrationDto> | null;
}
/**
 * 
 * @export
 * @interface StandWithZoneDto
 */
export interface StandWithZoneDto {
    /**
     * 
     * @type {number}
     * @memberof StandWithZoneDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StandWithZoneDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StandWithZoneDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StandWithZoneDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandWithZoneDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StandWithZoneDto
     */
    applicationNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandWithZoneDto
     */
    institutionId?: number | null;
    /**
     * 
     * @type {InstitutionDto}
     * @memberof StandWithZoneDto
     */
    institution?: InstitutionDto;
    /**
     * 
     * @type {number}
     * @memberof StandWithZoneDto
     */
    zoneId?: number | null;
    /**
     * 
     * @type {ZoneDto}
     * @memberof StandWithZoneDto
     */
    zone?: ZoneDto;
    /**
     * 
     * @type {string}
     * @memberof StandWithZoneDto
     */
    standNumber?: string | null;
    /**
     * 
     * @type {Array<DemonstrationDto>}
     * @memberof StandWithZoneDto
     */
    demonstrations?: Array<DemonstrationDto> | null;
}
/**
 * 
 * @export
 * @interface StartForgotPasswordDto
 */
export interface StartForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof StartForgotPasswordDto
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface YouAreHereCreateDto
 */
export interface YouAreHereCreateDto {
    /**
     * 
     * @type {number}
     * @memberof YouAreHereCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereCreateDto
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereCreateDto
     */
    y?: number;
}
/**
 * 
 * @export
 * @interface YouAreHereDetailsDto
 */
export interface YouAreHereDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof YouAreHereDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereDetailsDto
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereDetailsDto
     */
    y?: number;
}
/**
 * 
 * @export
 * @interface YouAreHereDto
 */
export interface YouAreHereDto {
    /**
     * 
     * @type {number}
     * @memberof YouAreHereDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereDto
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereDto
     */
    y?: number;
}
/**
 * 
 * @export
 * @interface YouAreHereUpdateDto
 */
export interface YouAreHereUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof YouAreHereUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof YouAreHereUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereUpdateDto
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof YouAreHereUpdateDto
     */
    y?: number;
}
/**
 * 
 * @export
 * @interface ZoneCreateDto
 */
export interface ZoneCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ZoneCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ZoneCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ZoneCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ZoneCreateDto
     */
    primaryColor?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ZoneCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ZoneCreateDto
     */
    illustrationId?: number;
    /**
     * 
     * @type {ImageDto}
     * @memberof ZoneCreateDto
     */
    illustration?: ImageDto;
    /**
     * 
     * @type {string}
     * @memberof ZoneCreateDto
     */
    zoneIdentifier?: string | null;
}
/**
 * 
 * @export
 * @interface ZoneDetailsDto
 */
export interface ZoneDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ZoneDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ZoneDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ZoneDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ZoneDetailsDto
     */
    primaryColor?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ZoneDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ZoneDetailsDto
     */
    illustrationId?: number;
    /**
     * 
     * @type {ImageDto}
     * @memberof ZoneDetailsDto
     */
    illustration?: ImageDto;
    /**
     * 
     * @type {string}
     * @memberof ZoneDetailsDto
     */
    zoneIdentifier?: string | null;
}
/**
 * 
 * @export
 * @interface ZoneDto
 */
export interface ZoneDto {
    /**
     * 
     * @type {number}
     * @memberof ZoneDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ZoneDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ZoneDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ZoneDto
     */
    primaryColor?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ZoneDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ZoneDto
     */
    illustrationId?: number;
    /**
     * 
     * @type {ImageDto}
     * @memberof ZoneDto
     */
    illustration?: ImageDto;
    /**
     * 
     * @type {string}
     * @memberof ZoneDto
     */
    zoneIdentifier?: string | null;
}
/**
 * 
 * @export
 * @interface ZoneUpdateDto
 */
export interface ZoneUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ZoneUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ZoneUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ZoneUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ZoneUpdateDto
     */
    primaryColor?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ZoneUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ZoneUpdateDto
     */
    illustrationId?: number;
    /**
     * 
     * @type {ImageDto}
     * @memberof ZoneUpdateDto
     */
    illustration?: ImageDto;
    /**
     * 
     * @type {string}
     * @memberof ZoneUpdateDto
     */
    zoneIdentifier?: string | null;
}

/**
 * ApplicationUserApi - axios parameter creator
 * @export
 */
export const ApplicationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost: async (applicationUserActivationDto?: ApplicationUserActivationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserActivationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost: async (startForgotPasswordDto?: StartForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/beginForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserChangePasswordPost: async (applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserChangePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserConfirmUserEmailPost: async (applicationUserActivationDto?: ApplicationUserActivationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/confirmUserEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserActivationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost: async (endForgotPasswordDto?: EndForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/endForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdDelete', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPatch', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPut', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost: async (id: number, applicationUserRole?: Array<ApplicationUserRole>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdRolesPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost: async (applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserRegisterUserPost: async (applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/registerUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserUpdateConsentsDto} [applicationUserUpdateConsentsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateConsentsPost: async (applicationUserUpdateConsentsDto?: ApplicationUserUpdateConsentsDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/updateConsents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserUpdateConsentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} standId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standIdAddToFavouritesPost: async (standId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'standId' is not null or undefined
            assertParamExists('standIdAddToFavouritesPost', 'standId', standId)
            const localVarPath = `/{standId}/addToFavourites`
                .replace(`{${"standId"}}`, encodeURIComponent(String(standId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} standId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standIdRemoveFromFavouritesDelete: async (standId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'standId' is not null or undefined
            assertParamExists('standIdRemoveFromFavouritesDelete', 'standId', standId)
            const localVarPath = `/{standId}/removeFromFavourites`
                .replace(`{${"standId"}}`, encodeURIComponent(String(standId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationUserApi - functional programming interface
 * @export
 */
export const ApplicationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserActivatePost(applicationUserActivationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserConfirmUserEmailPost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserConfirmUserEmailPost(applicationUserActivationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPut(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdRolesPost(id, applicationUserRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPost(applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserRegisterUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserRegisterUserPost(applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserUpdateConsentsDto} [applicationUserUpdateConsentsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUpdateConsentsPost(applicationUserUpdateConsentsDto?: ApplicationUserUpdateConsentsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUpdateConsentsPost(applicationUserUpdateConsentsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} standId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standIdAddToFavouritesPost(standId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.standIdAddToFavouritesPost(standId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} standId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async standIdRemoveFromFavouritesDelete(standId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.standIdRemoveFromFavouritesDelete(standId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationUserApi - factory interface
 * @export
 */
export const ApplicationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserConfirmUserEmailPost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserConfirmUserEmailPost(applicationUserActivationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet(id: number, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserRegisterUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserRegisterUserPost(applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserUpdateConsentsDto} [applicationUserUpdateConsentsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUpdateConsentsPost(applicationUserUpdateConsentsDto?: ApplicationUserUpdateConsentsDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserUpdateConsentsPost(applicationUserUpdateConsentsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} standId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standIdAddToFavouritesPost(standId: number, options?: any): AxiosPromise<void> {
            return localVarFp.standIdAddToFavouritesPost(standId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} standId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        standIdRemoveFromFavouritesDelete(standId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.standIdRemoveFromFavouritesDelete(standId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationUserApi - object-oriented interface
 * @export
 * @class ApplicationUserApi
 * @extends {BaseAPI}
 */
export class ApplicationUserApi extends BaseAPI {
    /**
     * 
     * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserConfirmUserEmailPost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserConfirmUserEmailPost(applicationUserActivationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdDelete(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<ApplicationUserRole>} [applicationUserRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserRegisterUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserRegisterUserPost(applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserUpdateConsentsDto} [applicationUserUpdateConsentsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserUpdateConsentsPost(applicationUserUpdateConsentsDto?: ApplicationUserUpdateConsentsDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserUpdateConsentsPost(applicationUserUpdateConsentsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} standId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public standIdAddToFavouritesPost(standId: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).standIdAddToFavouritesPost(standId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} standId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public standIdRemoveFromFavouritesDelete(standId: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).standIdRemoveFromFavouritesDelete(standId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet: async (returnUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/loginCallback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet: async (loginPageUrl?: string, returnUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (loginPageUrl !== undefined) {
                localVarQueryParameter['loginPageUrl'] = loginPageUrl;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost: async (returnUrl?: string, loginDto?: LoginDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet: async (postLogoutUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (postLogoutUrl !== undefined) {
                localVarQueryParameter['postLogoutUrl'] = postLogoutUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginCallbackGet(returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginPost(returnUrl, loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLogoutGet(postLogoutUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [loginPageUrl] 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnUrl] 
     * @param {LoginDto} [loginDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [postLogoutUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemonstrationApi - axios parameter creator
 * @export
 */
export const DemonstrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demonstration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDemonstrationIdDelete', 'id', id)
            const localVarPath = `/api/Demonstration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDemonstrationIdGet', 'id', id)
            const localVarPath = `/api/Demonstration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DemonstrationUpdateDto} [demonstrationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationIdPatch: async (id: number, demonstrationUpdateDto?: DemonstrationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDemonstrationIdPatch', 'id', id)
            const localVarPath = `/api/Demonstration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(demonstrationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DemonstrationUpdateDto} [demonstrationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationIdPut: async (id: number, demonstrationUpdateDto?: DemonstrationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDemonstrationIdPut', 'id', id)
            const localVarPath = `/api/Demonstration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(demonstrationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DemonstrationCreateDto} [demonstrationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationPost: async (demonstrationCreateDto?: DemonstrationCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demonstration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(demonstrationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemonstrationApi - functional programming interface
 * @export
 */
export const DemonstrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemonstrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemonstrationGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemonstrationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemonstrationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemonstrationIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemonstrationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemonstrationIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemonstrationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemonstrationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DemonstrationUpdateDto} [demonstrationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemonstrationIdPatch(id: number, demonstrationUpdateDto?: DemonstrationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemonstrationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemonstrationIdPatch(id, demonstrationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DemonstrationUpdateDto} [demonstrationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemonstrationIdPut(id: number, demonstrationUpdateDto?: DemonstrationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemonstrationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemonstrationIdPut(id, demonstrationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DemonstrationCreateDto} [demonstrationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemonstrationPost(demonstrationCreateDto?: DemonstrationCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemonstrationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemonstrationPost(demonstrationCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemonstrationApi - factory interface
 * @export
 */
export const DemonstrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemonstrationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationGet(options?: any): AxiosPromise<Array<DemonstrationDto>> {
            return localVarFp.apiDemonstrationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiDemonstrationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationIdGet(id: number, options?: any): AxiosPromise<DemonstrationDetailsDto> {
            return localVarFp.apiDemonstrationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DemonstrationUpdateDto} [demonstrationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationIdPatch(id: number, demonstrationUpdateDto?: DemonstrationUpdateDto, options?: any): AxiosPromise<DemonstrationDetailsDto> {
            return localVarFp.apiDemonstrationIdPatch(id, demonstrationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DemonstrationUpdateDto} [demonstrationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationIdPut(id: number, demonstrationUpdateDto?: DemonstrationUpdateDto, options?: any): AxiosPromise<DemonstrationDetailsDto> {
            return localVarFp.apiDemonstrationIdPut(id, demonstrationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DemonstrationCreateDto} [demonstrationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemonstrationPost(demonstrationCreateDto?: DemonstrationCreateDto, options?: any): AxiosPromise<DemonstrationDetailsDto> {
            return localVarFp.apiDemonstrationPost(demonstrationCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemonstrationApi - object-oriented interface
 * @export
 * @class DemonstrationApi
 * @extends {BaseAPI}
 */
export class DemonstrationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemonstrationApi
     */
    public apiDemonstrationGet(options?: any) {
        return DemonstrationApiFp(this.configuration).apiDemonstrationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemonstrationApi
     */
    public apiDemonstrationIdDelete(id: number, options?: any) {
        return DemonstrationApiFp(this.configuration).apiDemonstrationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemonstrationApi
     */
    public apiDemonstrationIdGet(id: number, options?: any) {
        return DemonstrationApiFp(this.configuration).apiDemonstrationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DemonstrationUpdateDto} [demonstrationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemonstrationApi
     */
    public apiDemonstrationIdPatch(id: number, demonstrationUpdateDto?: DemonstrationUpdateDto, options?: any) {
        return DemonstrationApiFp(this.configuration).apiDemonstrationIdPatch(id, demonstrationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DemonstrationUpdateDto} [demonstrationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemonstrationApi
     */
    public apiDemonstrationIdPut(id: number, demonstrationUpdateDto?: DemonstrationUpdateDto, options?: any) {
        return DemonstrationApiFp(this.configuration).apiDemonstrationIdPut(id, demonstrationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DemonstrationCreateDto} [demonstrationCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemonstrationApi
     */
    public apiDemonstrationPost(demonstrationCreateDto?: DemonstrationCreateDto, options?: any) {
        return DemonstrationApiFp(this.configuration).apiDemonstrationPost(demonstrationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdDelete', 'id', id)
            const localVarPath = `/api/Image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdFileStreamGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdFileStreamGet', 'id', id)
            const localVarPath = `/api/Image/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdGet', 'id', id)
            const localVarPath = `/api/Image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdPatch: async (id: number, imageUpdateDto?: ImageUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdPatch', 'id', id)
            const localVarPath = `/api/Image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdPut: async (id: number, imageUpdateDto?: ImageUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdPut', 'id', id)
            const localVarPath = `/api/Image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImagePost: async (file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdFileStreamGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdPatch(id: number, imageUpdateDto?: ImageUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdPatch(id, imageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdPut(id: number, imageUpdateDto?: ImageUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdPut(id, imageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImagePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImagePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageGet(options?: any): AxiosPromise<Array<ImageDto>> {
            return localVarFp.apiImageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiImageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiImageIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdGet(id: number, options?: any): AxiosPromise<ImageDetailsDto> {
            return localVarFp.apiImageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdPatch(id: number, imageUpdateDto?: ImageUpdateDto, options?: any): AxiosPromise<ImageDetailsDto> {
            return localVarFp.apiImageIdPatch(id, imageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdPut(id: number, imageUpdateDto?: ImageUpdateDto, options?: any): AxiosPromise<ImageDetailsDto> {
            return localVarFp.apiImageIdPut(id, imageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImagePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<ImageDetailsDto> {
            return localVarFp.apiImagePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageGet(options?: any) {
        return ImageApiFp(this.configuration).apiImageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdDelete(id: number, options?: any) {
        return ImageApiFp(this.configuration).apiImageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdFileStreamGet(id: number, options?: any) {
        return ImageApiFp(this.configuration).apiImageIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdGet(id: number, options?: any) {
        return ImageApiFp(this.configuration).apiImageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ImageUpdateDto} [imageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdPatch(id: number, imageUpdateDto?: ImageUpdateDto, options?: any) {
        return ImageApiFp(this.configuration).apiImageIdPatch(id, imageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ImageUpdateDto} [imageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdPut(id: number, imageUpdateDto?: ImageUpdateDto, options?: any) {
        return ImageApiFp(this.configuration).apiImageIdPut(id, imageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImagePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return ImageApiFp(this.configuration).apiImagePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstitutionApi - axios parameter creator
 * @export
 */
export const InstitutionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Institution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInstitutionIdDelete', 'id', id)
            const localVarPath = `/api/Institution/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInstitutionIdGet', 'id', id)
            const localVarPath = `/api/Institution/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InstitutionUpdateDto} [institutionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionIdPatch: async (id: number, institutionUpdateDto?: InstitutionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInstitutionIdPatch', 'id', id)
            const localVarPath = `/api/Institution/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(institutionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InstitutionUpdateDto} [institutionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionIdPut: async (id: number, institutionUpdateDto?: InstitutionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInstitutionIdPut', 'id', id)
            const localVarPath = `/api/Institution/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(institutionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InstitutionCreateDto} [institutionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionPost: async (institutionCreateDto?: InstitutionCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Institution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(institutionCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstitutionApi - functional programming interface
 * @export
 */
export const InstitutionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstitutionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInstitutionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstitutionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInstitutionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInstitutionIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInstitutionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInstitutionIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInstitutionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InstitutionUpdateDto} [institutionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInstitutionIdPatch(id: number, institutionUpdateDto?: InstitutionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInstitutionIdPatch(id, institutionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InstitutionUpdateDto} [institutionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInstitutionIdPut(id: number, institutionUpdateDto?: InstitutionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInstitutionIdPut(id, institutionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InstitutionCreateDto} [institutionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInstitutionPost(institutionCreateDto?: InstitutionCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInstitutionPost(institutionCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstitutionApi - factory interface
 * @export
 */
export const InstitutionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstitutionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionGet(options?: any): AxiosPromise<Array<InstitutionDto>> {
            return localVarFp.apiInstitutionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInstitutionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionIdGet(id: number, options?: any): AxiosPromise<InstitutionDetailsDto> {
            return localVarFp.apiInstitutionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InstitutionUpdateDto} [institutionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionIdPatch(id: number, institutionUpdateDto?: InstitutionUpdateDto, options?: any): AxiosPromise<InstitutionDetailsDto> {
            return localVarFp.apiInstitutionIdPatch(id, institutionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InstitutionUpdateDto} [institutionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionIdPut(id: number, institutionUpdateDto?: InstitutionUpdateDto, options?: any): AxiosPromise<InstitutionDetailsDto> {
            return localVarFp.apiInstitutionIdPut(id, institutionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InstitutionCreateDto} [institutionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInstitutionPost(institutionCreateDto?: InstitutionCreateDto, options?: any): AxiosPromise<InstitutionDetailsDto> {
            return localVarFp.apiInstitutionPost(institutionCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstitutionApi - object-oriented interface
 * @export
 * @class InstitutionApi
 * @extends {BaseAPI}
 */
export class InstitutionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutionApi
     */
    public apiInstitutionGet(options?: any) {
        return InstitutionApiFp(this.configuration).apiInstitutionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutionApi
     */
    public apiInstitutionIdDelete(id: number, options?: any) {
        return InstitutionApiFp(this.configuration).apiInstitutionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutionApi
     */
    public apiInstitutionIdGet(id: number, options?: any) {
        return InstitutionApiFp(this.configuration).apiInstitutionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InstitutionUpdateDto} [institutionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutionApi
     */
    public apiInstitutionIdPatch(id: number, institutionUpdateDto?: InstitutionUpdateDto, options?: any) {
        return InstitutionApiFp(this.configuration).apiInstitutionIdPatch(id, institutionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InstitutionUpdateDto} [institutionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutionApi
     */
    public apiInstitutionIdPut(id: number, institutionUpdateDto?: InstitutionUpdateDto, options?: any) {
        return InstitutionApiFp(this.configuration).apiInstitutionIdPut(id, institutionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InstitutionCreateDto} [institutionCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutionApi
     */
    public apiInstitutionPost(institutionCreateDto?: InstitutionCreateDto, options?: any) {
        return InstitutionApiFp(this.configuration).apiInstitutionPost(institutionCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StandApi - axios parameter creator
 * @export
 */
export const StandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStandIdDelete', 'id', id)
            const localVarPath = `/api/Stand/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStandIdGet', 'id', id)
            const localVarPath = `/api/Stand/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {StandUpdateDto} [standUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandIdPatch: async (id: number, standUpdateDto?: StandUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStandIdPatch', 'id', id)
            const localVarPath = `/api/Stand/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(standUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {StandUpdateDto} [standUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandIdPut: async (id: number, standUpdateDto?: StandUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStandIdPut', 'id', id)
            const localVarPath = `/api/Stand/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(standUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StandCreateDto} [standCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandPost: async (standCreateDto?: StandCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(standCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIdStandsPathGet: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userIdStandsPathGet', 'userId', userId)
            const localVarPath = `/{userId}/standsPath`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StandApi - functional programming interface
 * @export
 */
export const StandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStandGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StandDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStandGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStandIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStandIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStandIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StandDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStandIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {StandUpdateDto} [standUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStandIdPatch(id: number, standUpdateDto?: StandUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StandDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStandIdPatch(id, standUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {StandUpdateDto} [standUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStandIdPut(id: number, standUpdateDto?: StandUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StandDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStandIdPut(id, standUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StandCreateDto} [standCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStandPost(standCreateDto?: StandCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StandDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStandPost(standCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userIdStandsPathGet(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StandWithZoneDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userIdStandsPathGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StandApi - factory interface
 * @export
 */
export const StandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StandApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandGet(options?: any): AxiosPromise<Array<StandDto>> {
            return localVarFp.apiStandGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiStandIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandIdGet(id: number, options?: any): AxiosPromise<StandDetailsDto> {
            return localVarFp.apiStandIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {StandUpdateDto} [standUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandIdPatch(id: number, standUpdateDto?: StandUpdateDto, options?: any): AxiosPromise<StandDetailsDto> {
            return localVarFp.apiStandIdPatch(id, standUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {StandUpdateDto} [standUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandIdPut(id: number, standUpdateDto?: StandUpdateDto, options?: any): AxiosPromise<StandDetailsDto> {
            return localVarFp.apiStandIdPut(id, standUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StandCreateDto} [standCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandPost(standCreateDto?: StandCreateDto, options?: any): AxiosPromise<StandDetailsDto> {
            return localVarFp.apiStandPost(standCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIdStandsPathGet(userId: number, options?: any): AxiosPromise<Array<StandWithZoneDto>> {
            return localVarFp.userIdStandsPathGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StandApi - object-oriented interface
 * @export
 * @class StandApi
 * @extends {BaseAPI}
 */
export class StandApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandApi
     */
    public apiStandGet(options?: any) {
        return StandApiFp(this.configuration).apiStandGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandApi
     */
    public apiStandIdDelete(id: number, options?: any) {
        return StandApiFp(this.configuration).apiStandIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandApi
     */
    public apiStandIdGet(id: number, options?: any) {
        return StandApiFp(this.configuration).apiStandIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {StandUpdateDto} [standUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandApi
     */
    public apiStandIdPatch(id: number, standUpdateDto?: StandUpdateDto, options?: any) {
        return StandApiFp(this.configuration).apiStandIdPatch(id, standUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {StandUpdateDto} [standUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandApi
     */
    public apiStandIdPut(id: number, standUpdateDto?: StandUpdateDto, options?: any) {
        return StandApiFp(this.configuration).apiStandIdPut(id, standUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StandCreateDto} [standCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandApi
     */
    public apiStandPost(standCreateDto?: StandCreateDto, options?: any) {
        return StandApiFp(this.configuration).apiStandPost(standCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandApi
     */
    public userIdStandsPathGet(userId: number, options?: any) {
        return StandApiFp(this.configuration).userIdStandsPathGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * YouAreHereApi - axios parameter creator
 * @export
 */
export const YouAreHereApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHereGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/YouAreHere`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHereIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiYouAreHereIdDelete', 'id', id)
            const localVarPath = `/api/YouAreHere/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHereIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiYouAreHereIdGet', 'id', id)
            const localVarPath = `/api/YouAreHere/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {YouAreHereUpdateDto} [youAreHereUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHereIdPatch: async (id: number, youAreHereUpdateDto?: YouAreHereUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiYouAreHereIdPatch', 'id', id)
            const localVarPath = `/api/YouAreHere/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(youAreHereUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {YouAreHereUpdateDto} [youAreHereUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHereIdPut: async (id: number, youAreHereUpdateDto?: YouAreHereUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiYouAreHereIdPut', 'id', id)
            const localVarPath = `/api/YouAreHere/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(youAreHereUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {YouAreHereCreateDto} [youAreHereCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHerePost: async (youAreHereCreateDto?: YouAreHereCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/YouAreHere`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(youAreHereCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YouAreHereApi - functional programming interface
 * @export
 */
export const YouAreHereApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = YouAreHereApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiYouAreHereGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YouAreHereDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiYouAreHereGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiYouAreHereIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiYouAreHereIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiYouAreHereIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YouAreHereDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiYouAreHereIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {YouAreHereUpdateDto} [youAreHereUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiYouAreHereIdPatch(id: number, youAreHereUpdateDto?: YouAreHereUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YouAreHereDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiYouAreHereIdPatch(id, youAreHereUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {YouAreHereUpdateDto} [youAreHereUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiYouAreHereIdPut(id: number, youAreHereUpdateDto?: YouAreHereUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YouAreHereDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiYouAreHereIdPut(id, youAreHereUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {YouAreHereCreateDto} [youAreHereCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiYouAreHerePost(youAreHereCreateDto?: YouAreHereCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YouAreHereDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiYouAreHerePost(youAreHereCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * YouAreHereApi - factory interface
 * @export
 */
export const YouAreHereApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = YouAreHereApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHereGet(options?: any): AxiosPromise<Array<YouAreHereDto>> {
            return localVarFp.apiYouAreHereGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHereIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiYouAreHereIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHereIdGet(id: number, options?: any): AxiosPromise<YouAreHereDetailsDto> {
            return localVarFp.apiYouAreHereIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {YouAreHereUpdateDto} [youAreHereUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHereIdPatch(id: number, youAreHereUpdateDto?: YouAreHereUpdateDto, options?: any): AxiosPromise<YouAreHereDetailsDto> {
            return localVarFp.apiYouAreHereIdPatch(id, youAreHereUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {YouAreHereUpdateDto} [youAreHereUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHereIdPut(id: number, youAreHereUpdateDto?: YouAreHereUpdateDto, options?: any): AxiosPromise<YouAreHereDetailsDto> {
            return localVarFp.apiYouAreHereIdPut(id, youAreHereUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YouAreHereCreateDto} [youAreHereCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiYouAreHerePost(youAreHereCreateDto?: YouAreHereCreateDto, options?: any): AxiosPromise<YouAreHereDetailsDto> {
            return localVarFp.apiYouAreHerePost(youAreHereCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * YouAreHereApi - object-oriented interface
 * @export
 * @class YouAreHereApi
 * @extends {BaseAPI}
 */
export class YouAreHereApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YouAreHereApi
     */
    public apiYouAreHereGet(options?: any) {
        return YouAreHereApiFp(this.configuration).apiYouAreHereGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YouAreHereApi
     */
    public apiYouAreHereIdDelete(id: number, options?: any) {
        return YouAreHereApiFp(this.configuration).apiYouAreHereIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YouAreHereApi
     */
    public apiYouAreHereIdGet(id: number, options?: any) {
        return YouAreHereApiFp(this.configuration).apiYouAreHereIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {YouAreHereUpdateDto} [youAreHereUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YouAreHereApi
     */
    public apiYouAreHereIdPatch(id: number, youAreHereUpdateDto?: YouAreHereUpdateDto, options?: any) {
        return YouAreHereApiFp(this.configuration).apiYouAreHereIdPatch(id, youAreHereUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {YouAreHereUpdateDto} [youAreHereUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YouAreHereApi
     */
    public apiYouAreHereIdPut(id: number, youAreHereUpdateDto?: YouAreHereUpdateDto, options?: any) {
        return YouAreHereApiFp(this.configuration).apiYouAreHereIdPut(id, youAreHereUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YouAreHereCreateDto} [youAreHereCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YouAreHereApi
     */
    public apiYouAreHerePost(youAreHereCreateDto?: YouAreHereCreateDto, options?: any) {
        return YouAreHereApiFp(this.configuration).apiYouAreHerePost(youAreHereCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ZoneApi - axios parameter creator
 * @export
 */
export const ZoneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZoneGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Zone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZoneIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiZoneIdDelete', 'id', id)
            const localVarPath = `/api/Zone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZoneIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiZoneIdGet', 'id', id)
            const localVarPath = `/api/Zone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ZoneUpdateDto} [zoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZoneIdPatch: async (id: number, zoneUpdateDto?: ZoneUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiZoneIdPatch', 'id', id)
            const localVarPath = `/api/Zone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ZoneUpdateDto} [zoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZoneIdPut: async (id: number, zoneUpdateDto?: ZoneUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiZoneIdPut', 'id', id)
            const localVarPath = `/api/Zone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneCreateDto} [zoneCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZonePost: async (zoneCreateDto?: ZoneCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Zone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZoneApi - functional programming interface
 * @export
 */
export const ZoneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZoneApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZoneGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZoneGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZoneIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZoneIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZoneIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZoneIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ZoneUpdateDto} [zoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZoneIdPatch(id: number, zoneUpdateDto?: ZoneUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZoneIdPatch(id, zoneUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ZoneUpdateDto} [zoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZoneIdPut(id: number, zoneUpdateDto?: ZoneUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZoneIdPut(id, zoneUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ZoneCreateDto} [zoneCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZonePost(zoneCreateDto?: ZoneCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZonePost(zoneCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZoneApi - factory interface
 * @export
 */
export const ZoneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZoneApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZoneGet(options?: any): AxiosPromise<Array<ZoneDto>> {
            return localVarFp.apiZoneGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZoneIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiZoneIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZoneIdGet(id: number, options?: any): AxiosPromise<ZoneDetailsDto> {
            return localVarFp.apiZoneIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ZoneUpdateDto} [zoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZoneIdPatch(id: number, zoneUpdateDto?: ZoneUpdateDto, options?: any): AxiosPromise<ZoneDetailsDto> {
            return localVarFp.apiZoneIdPatch(id, zoneUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ZoneUpdateDto} [zoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZoneIdPut(id: number, zoneUpdateDto?: ZoneUpdateDto, options?: any): AxiosPromise<ZoneDetailsDto> {
            return localVarFp.apiZoneIdPut(id, zoneUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneCreateDto} [zoneCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZonePost(zoneCreateDto?: ZoneCreateDto, options?: any): AxiosPromise<ZoneDetailsDto> {
            return localVarFp.apiZonePost(zoneCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZoneApi - object-oriented interface
 * @export
 * @class ZoneApi
 * @extends {BaseAPI}
 */
export class ZoneApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public apiZoneGet(options?: any) {
        return ZoneApiFp(this.configuration).apiZoneGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public apiZoneIdDelete(id: number, options?: any) {
        return ZoneApiFp(this.configuration).apiZoneIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public apiZoneIdGet(id: number, options?: any) {
        return ZoneApiFp(this.configuration).apiZoneIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ZoneUpdateDto} [zoneUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public apiZoneIdPatch(id: number, zoneUpdateDto?: ZoneUpdateDto, options?: any) {
        return ZoneApiFp(this.configuration).apiZoneIdPatch(id, zoneUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ZoneUpdateDto} [zoneUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public apiZoneIdPut(id: number, zoneUpdateDto?: ZoneUpdateDto, options?: any) {
        return ZoneApiFp(this.configuration).apiZoneIdPut(id, zoneUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneCreateDto} [zoneCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoneApi
     */
    public apiZonePost(zoneCreateDto?: ZoneCreateDto, options?: any) {
        return ZoneApiFp(this.configuration).apiZonePost(zoneCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


