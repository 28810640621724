import React from "react";
import {Route, Routes} from "react-router-dom";
import {RoutingDefinition} from "digimuth-components/dist/components/crudBreadcrumb/crudBreadcrumb";
import styles from "./routingContainer.module.less";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import * as Pages from "./pages";
import {useAuth} from "oidc-react";

const mainPage = {route: "/cms", name: "Strona główna"};
const youAreHereTable = {route: "/cms/youAreHere", name: "Kody QR", breadcrumbEntries: [mainPage, {name: "Kody QR"}]};
const demonstrationTable = {route: "/cms/demonstration", name: "Pokazy", breadcrumbEntries: [mainPage, {name: "Pokazy"}]};
const standTable = {route: "/cms/stand", name: "Stanowiska", breadcrumbEntries: [mainPage, {name: "Stanowiska"}]};
const zoneTable = {route: "/cms/zone", name: "Strefy", breadcrumbEntries: [mainPage, {name: "Strefy"}]};
const institutionTable = {route: "/cms/institution", name: "Instytucje", breadcrumbEntries: [mainPage, {name: "Instytucje"}]};

export const RoutingPaths: { [key: string]: RoutingDefinition } = {
    youAreHereAdd: {route: "/cms/youAreHere/add", breadcrumbEntries: [mainPage, youAreHereTable, { name: "Dodaj" }]},
    youAreHereEdit: {route: "/cms/youAreHere/:id/edit", breadcrumbEntries: [mainPage, youAreHereTable, { name: "Edytuj" }]},
    youAreHereTable,

    demonstrationAdd: {route: "/cms/demonstration/add", breadcrumbEntries: [mainPage, demonstrationTable, { name: "Dodaj" }]},
    demonstrationEdit: {route: "/cms/demonstration/:id/edit", breadcrumbEntries: [mainPage, demonstrationTable, { name: "Edytuj" }]},
    demonstrationTable,

    standAdd: {route: "/cms/stand/add", breadcrumbEntries: [mainPage, standTable, { name: "Dodaj" }]},
    standEdit: {route: "/cms/stand/:id/edit", breadcrumbEntries: [mainPage, standTable, { name: "Edytuj" }]},
    standTable,

    zoneAdd: {route: "/cms/zone/add", breadcrumbEntries: [mainPage, zoneTable, { name: "Dodaj" }]},
    zoneEdit: {route: "/cms/zone/:id/edit", breadcrumbEntries: [mainPage, zoneTable, { name: "Edytuj" }]},
    zoneTable,

    institutionAdd: {route: "/cms/institution/add", breadcrumbEntries: [mainPage, institutionTable, { name: "Dodaj" }]},
    institutionEdit: {route: "/cms/institution/:id/edit", breadcrumbEntries: [mainPage, institutionTable, { name: "Edytuj" }]},
    institutionTable,

    login: {route: "/login"},
    changePassword: {route: "/changePassword"},
    activate: {route: "/activate"},
    forgotPassword: {route: "/forgotPassword"},
    forgotPasswordComplete: {route: "/forgotPasswordComplete"},
    register: {route: "/register"},
    confirm: {route: "/confirm"},

    users: {route: "/cms/users", breadcrumbEntries: [mainPage, {name: "Użytkownicy"}]},
    mainPage: mainPage,
    favourites: {route: "/favourites"},
    allStands: {route: "/stands"},
    youAreHere: {route: "/youAreHere/:id"},
    youAreHereAlt: {route: "/you-are-here/:id"},
    privacy: { route: "/privacy" },
    homePage: {route: "/"}
};

const loadingIcon = <LoadingOutlined className={styles.loadingIcon} spin/>;
export const loadingSpin = <Spin className={styles.loadingContainer} indicator={loadingIcon}/>;

export default function RoutingContainer() {

    return <Routes>
        <Route path={RoutingPaths.youAreHereAdd.route} element={<Pages.YouAreHereFormPage/>}/>
        <Route path={RoutingPaths.youAreHereEdit.route} element={<Pages.YouAreHereFormPage/>}/>
        <Route path={RoutingPaths.youAreHereTable.route} element={<Pages.YouAreHerePage/>}/>

        <Route path={RoutingPaths.demonstrationAdd.route} element={<Pages.DemonstrationFormPage/>}/>
        <Route path={RoutingPaths.demonstrationEdit.route} element={<Pages.DemonstrationFormPage/>}/>
        <Route path={RoutingPaths.demonstrationTable.route} element={<Pages.DemonstrationPage/>}/>

        <Route path={RoutingPaths.standAdd.route} element={<Pages.StandFormPage/>}/>
        <Route path={RoutingPaths.standEdit.route} element={<Pages.StandFormPage/>}/>
        <Route path={RoutingPaths.standTable.route} element={<Pages.StandPage/>}/>

        <Route path={RoutingPaths.zoneAdd.route} element={<Pages.ZoneFormPage/>}/>
        <Route path={RoutingPaths.zoneEdit.route} element={<Pages.ZoneFormPage/>}/>
        <Route path={RoutingPaths.zoneTable.route} element={<Pages.ZonePage/>}/>

        <Route path={RoutingPaths.institutionAdd.route} element={<Pages.InstitutionFormPage/>}/>
        <Route path={RoutingPaths.institutionEdit.route} element={<Pages.InstitutionFormPage/>}/>
        <Route path={RoutingPaths.institutionTable.route} element={<Pages.InstitutionPage/>}/>

        <Route path={RoutingPaths.login.route} element={<Pages.LoginPage/>}/>
        <Route path={RoutingPaths.changePassword.route} element={<Pages.ChangePasswordPage/>}/>
        <Route path={RoutingPaths.users.route} element={<Pages.UsersPage/>}/>

        <Route path={RoutingPaths.forgotPassword.route} element={<Pages.ForgotPasswordPage/>}/>
        <Route path={RoutingPaths.forgotPasswordComplete.route} element={<Pages.ForgotPasswordCompletePage/>}/>
        <Route path={RoutingPaths.activate.route} element={<Pages.ActivatePage/>}/>
        <Route path={RoutingPaths.register.route} element={<Pages.RegisterPage/>}/>
        <Route path={RoutingPaths.confirm.route} element={<Pages.ConfirmPage/>}/>
        <Route path={RoutingPaths.favourites.route} element={<Pages.FavouritesPage/>}/>
        <Route path={RoutingPaths.allStands.route} element={<Pages.AllStandsPage/>}/>
        <Route path={RoutingPaths.youAreHere.route} element={<Pages.HomePage/>}/>
        <Route path={RoutingPaths.youAreHereAlt.route} element={<Pages.HomePage/>}/>

        <Route path={RoutingPaths.mainPage.route} element={<Pages.MainPage/>}/>
        <Route path={RoutingPaths.privacy.route} element={<Pages.PrivacyPage/>}/>

        <Route path={RoutingPaths.homePage.route} element={<Pages.HomePage/>}/>
        <Route path='*' element={<Pages.HomePage/>}/>
    </Routes>
}
