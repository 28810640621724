import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    contrast: localStorage.getItem("highContrast") == "true",
    facilities: localStorage.getItem("facilities") == "true"
};

const settingsSlice = createSlice({
    name: "settingsSlice",
    initialState,
    reducers: {
        setContrast: (state, action) => {
            localStorage.setItem("highContrast", action.payload.toString());
            state.contrast = action.payload;
        },
        setFacilities: (state, action) => {
            localStorage.setItem("facilities", action.payload.toString());
            state.facilities = action.payload;
        },
    }
});

export const {setContrast, setFacilities} = settingsSlice.actions;

export const getContrast = (state: any) => state.settings.contrast;
export const getFacilities = (state: any) => state.settings.facilities;

export default settingsSlice.reducer;
