import './App.less';
import {Provider as InversifyProvider, useInjection} from 'inversify-react';
import {Provider, useDispatch, useSelector} from 'react-redux'
import {store} from './redux/store';
import RoutingContainer, {RoutingPaths} from "./pages/routing/routingContainer";
import React, {useCallback, useEffect} from "react";
import {unstable_HistoryRouter as HistoryRouter, useLocation} from "react-router-dom";
import ApiService from "./services/apiService";
import SiderMenu from './pages/components/siderMenu/siderMenu';
import HeaderContainer from './pages/components/header/headerContainer';
import {CrudBreadcrumb, CrudLayout} from 'digimuth-components/dist';
import {Container} from 'inversify';
import {AuthProvider, AuthProviderProps, initUserManager, useAuth, UserManager} from 'oidc-react';
import {createBrowserHistory} from "history";
import {ApplicationUserApi} from "./api";
import {getCurrentUser, setCurrentUser} from "./redux/applicationUserSlice";
import {getContrast} from './redux/settingsSlice';
import {ConfigProvider, ThemeConfig} from "antd";
import ReactGA from "react-ga4";

const authSettings: AuthProviderProps = {
    authority: process.env.REACT_APP_URL,
    clientId: "Digimuth.CnKopernikMap.Web",
    redirectUri: process.env.REACT_APP_URL,
    autoSignIn: false,
    automaticSilentRenew: true,
    scope: "openid Digimuth.CnKopernikMap.WebAPI profile offline_access",
    loadUserInfo: true
};

export const userManager: UserManager | undefined = initUserManager(authSettings);

const customTheme: ThemeConfig = {
    token: {
        colorPrimary: '#00C4F4',
        colorWarning: '#DE6826',
        borderRadius: 6,
        colorBgContainerDisabled: '#E5EDFB',
        controlHeight: 34,
        fontFamily: '"Barlow", "Inter", sans-serif',
    },
    components: {
        Layout: {
            headerBg: '#2A2A2A',
            triggerBg: '#3A3A3A',
            bodyBg: '#fefefe',
            siderBg: "#353535",
            controlItemBgActive: "#353535"
        },
        Menu: {
            darkItemBg: "#353535",
            itemBorderRadius: 0,
            itemMarginInline: 0
        }
    }
};

function App() {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID!);

    const container = new Container();
    const history = createBrowserHistory();
    container.bind(ApiService).toSelf().inSingletonScope();

    return (
        /* // @ts-ignore */
        <AuthProvider
            userManager={userManager}
            onSignIn={u => {
                const anyState = u?.state as any;
                const fromState = anyState?.targetPath;
                const targetUrl = fromState ? fromState : "/";
                history.replace(targetUrl);
            }}
            autoSignIn={false}>
            <Provider store={store}>
                <InversifyProvider container={container}>
                    <HistoryRouter history={history}>
                        <ConfigProvider theme={customTheme}>
                            <Layout/>
                        </ConfigProvider>
                    </HistoryRouter>
                </InversifyProvider>
            </Provider>
        </AuthProvider>
    )
}

function Layout() {
    const auth = useAuth();
    const apiService = useInjection(ApiService);
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser);
    const location = useLocation();
    const highContrast = useSelector(getContrast);

    const updateCurrentUserInStore = useCallback(async () => {
        try {
            const response = await apiService.getApi(ApplicationUserApi)
                .apiApplicationUserIdGet(Number(auth.userData?.profile.sub));
            dispatch(setCurrentUser(response.data));
        } catch (e: any) {
            console.log(e.response?.data);
        }
    }, [auth.userData, apiService, dispatch]);

    useEffect(() => {
        if (!currentUser && auth.userData) {
            updateCurrentUserInStore();
        }
    }, [updateCurrentUserInStore, currentUser]);

    useEffect(() => {
        document.body.className = highContrast ? "high-contrast" : "";
    }, [highContrast]);

    return <CrudLayout
        siderFullHeight={true}
        breadcrumb={<CrudBreadcrumb routingDefinitions={RoutingPaths}/>}
        siderMenu={<SiderMenu/>}
        header={<HeaderContainer/>}
        showSider={location.pathname.startsWith("/cms")}
    >
        <RoutingContainer/>
    </CrudLayout>;
}

export default App;
