const headerStrings = {
    title: {
        "pl-PL": "Piknik naukowy",
        "uk-UA": "Piknik naukowy"
    },
    subtitle: {
        "pl-PL": "polskiego radia i centrum nauki kopernik",
        "uk-UA": "polskiego radia i centrum nauki kopernik"
    }
};

export default headerStrings;
