import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "reflect-metadata";
import {createRoot} from 'react-dom/client'

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register({
    onUpdate: registration => {
      const waitingServiceWorker = registration.waiting
  
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", event => {
          //@ts-ignore
          if (event.target!.state === "activated") {
            window.location.reload()
          }
        });
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
    }
  });

Promise.prototype.finally = Promise.prototype.finally || {
    finally (fn: any): any {
        const onFinally = (callback: any) => Promise.resolve(fn()).then(callback);
        return (this as any).then(
            (result: any) => onFinally(() => result),
            (reason: any) => onFinally(() => Promise.reject(reason))
        );
    }
  }.finally;