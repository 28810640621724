import {useAuth} from "oidc-react";
import HeaderComponent from "./header";
import {useNavigate} from "react-router-dom";
import {RoutingPaths} from "../../routing/routingContainer";

export default function HeaderContainer() {
    const auth = useAuth();
    const navigate = useNavigate();

    const onLogoutPressed = async () => {
        await auth.signOut();
        await auth.signOutRedirect();
        navigate(RoutingPaths.homePage.route);
    }

    const onLoginPressed = async () => {
        await auth.userManager.signinSilent().catch(() =>
            auth.signIn({state: {targetPath: RoutingPaths.homePage.route}}));
    }

    return <HeaderComponent
        onLogoutPressed={onLogoutPressed}
        userData={auth.userData}
        onLoginPressed={onLoginPressed}
    />
}
