import {
    AuditOutlined,
    BankOutlined, BarcodeOutlined,
    EnvironmentOutlined,
    FundProjectionScreenOutlined,
    UserOutlined
} from "@ant-design/icons";
import {Menu} from "antd";
import {NavLink} from "react-router-dom";
import {RoutingPaths} from "../../routing/routingContainer";
import styles from "./siderMenu.module.less"
import {useCurrentRoutingPath} from "digimuth-components/dist";
import React from "react";

export default function SiderMenu() {
    const route = useCurrentRoutingPath(RoutingPaths)?.route || "";

    return <>
        <NavLink to={"/cms"}>
            <h3 className={styles.logo}>Piknik Naukowy CMS</h3>
        </NavLink>
        <Menu theme="dark" selectedKeys={[route]} mode="inline">
            <Menu.Item key={RoutingPaths.users.route} icon={<UserOutlined/>}>
                <NavLink to={RoutingPaths.users.route}>
                    Użytkownicy
                </NavLink>
            </Menu.Item>
            <Menu.Item key={RoutingPaths.institutionTable.route} icon={<BankOutlined/>}>
                <NavLink to={RoutingPaths.institutionTable.route}>
                    Instytucje
                </NavLink>
            </Menu.Item>
            <Menu.Item key={RoutingPaths.zoneTable.route} icon={<EnvironmentOutlined/>}>
                <NavLink to={RoutingPaths.zoneTable.route}>
                    Strefy
                </NavLink>
            </Menu.Item>
            <Menu.Item key={RoutingPaths.standTable.route} icon={<AuditOutlined/>}>
                <NavLink to={RoutingPaths.standTable.route}>
                    Stanowiska
                </NavLink>
            </Menu.Item>
            <Menu.Item key={RoutingPaths.demonstrationTable.route} icon={<FundProjectionScreenOutlined/>}>
                <NavLink to={RoutingPaths.demonstrationTable.route}>
                    Pokazy
                </NavLink>
            </Menu.Item>
            <Menu.Item key={RoutingPaths.youAreHereTable.route} icon={<BarcodeOutlined/>}>
                <NavLink to={RoutingPaths.youAreHereTable.route}>
                    Kody QR
                </NavLink>
            </Menu.Item>
        </Menu>
    </>;
}
