import { EnvironmentOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { Header } from "antd/lib/layout/layout";
import { User } from "oidc-react";
import { useLayoutEffect, useRef, useState } from 'react';
import styles from './headerComponent.module.less';
import { AsyncButton } from "digimuth-components/dist";
import LanguageSelector from "../languageSelector/languageSelector";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalLanguage, setGlobalLanguage } from "../../../redux/languageSlice";
import { ApplicationUserRole } from "../../../api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RoutingPaths } from "../../routing/routingContainer";
import useCurrentUserRoles from "../../../hooks/useCurrentUserRoles";
import HeaderLogo from "../../../assets/headerLogo.png";
import { ReactComponent as Navigation } from "../../../assets/navigation.svg";
import MapSider from '../mapSider/mapSider';
import HeaderStrings from "./header.strings";

interface HeaderComponentProps {
    onLogoutPressed: () => Promise<any>,
    onLoginPressed: () => Promise<any>,
    userData?: User | null;
}

export default function HeaderComponent(props: HeaderComponentProps) {
    const dispatch = useDispatch();
    const headerRef = useRef<HTMLDivElement | null>(null)
    const userRoles = useCurrentUserRoles();
    const navigate = useNavigate();
    const location = useLocation();
    const [siderCollapsed, setSiderCollapsed] = useState(true);

    const onGlobalLanguage = (value: string) => {
        dispatch(setGlobalLanguage(value));
    }

    const onManagementSystemClick = () => {
        navigate(RoutingPaths.mainPage.route);
    }

    const onHomeClick = () => {
        window.location.href = RoutingPaths.homePage.route;
    }

    useLayoutEffect(() => {
        const target = headerRef.current
        if (!target) return
        const disablePinchZoom = (e: any) => {
            if (e.touches.length > 1) {
                e.preventDefault()
            }
        }
        target.addEventListener("touchmove", disablePinchZoom, { passive: false })
        return () => {
            target.removeEventListener("touchmove", disablePinchZoom)
        }
    }, [])

    if (props.userData) {
        return <>
            <Header className={styles.exHeader} ref={headerRef}>
                <div className={styles.headerItemsWrapper}>
                    <CnkLogo />
                    <div className={styles.userNameWrapper}>
                        {(userRoles?.includes(ApplicationUserRole.Admin) && !location.pathname.startsWith("/cms")) &&
                            <Button icon={<SettingOutlined />} onClick={onManagementSystemClick}>
                                Panel zarządzania
                            </Button>
                        }

                        {location.pathname.startsWith("/cms") ? <>
                            <Col>
                                <LanguageSelector onSelect={onGlobalLanguage} />
                            </Col>
                            <Button icon={<EnvironmentOutlined />} onClick={onHomeClick}>
                                Mapa Pikniku
                            </Button>
                            <span>{props.userData.profile.email}</span>
                            <AsyncButton type="text" onClick={props.onLogoutPressed} icon={<LogoutOutlined />}>
                                Wyloguj
                            </AsyncButton>
                        </> : null}
                    </div>
                    {siderCollapsed && <div className={styles.switch}>
                        <Button type="text" icon={<Navigation />} onClick={() => setSiderCollapsed(false)} />
                    </div>}
                </div>
            </Header>
            {!location.pathname.startsWith("/cms") &&
                <MapSider siderCollapsed={siderCollapsed} setSiderCollapsed={setSiderCollapsed} />}
        </>;
    }

    return <>
        <Header className={styles.exHeader} ref={headerRef}>
            <Row>
                <Col xs={22}>
                    <CnkLogo />
                </Col>
                <Col xs={2} className={styles.switch}>
                    {siderCollapsed &&
                        <Button type="text" icon={<Navigation />} onClick={() => setSiderCollapsed(false)} />}
                </Col>
            </Row>
        </Header>
        <MapSider siderCollapsed={siderCollapsed} setSiderCollapsed={setSiderCollapsed} />
    </>;
}

export function CnkLogo(props: { onClick?: () => void }) {
    const language = useSelector(getGlobalLanguage);

    return <Link to={"/"} onClick={props.onClick}>
        <div className={styles.logoWrapper}>
            <img className={styles.logo} src={HeaderLogo} alt={"logo"} />
            <div className={styles.logoName}>
                <span className={styles.number}>
                    27.
                </span>
                <span className={styles.title}>
                    {HeaderStrings.title[language]}
                </span>
                <span className={styles.subtitle}>
                    {HeaderStrings.subtitle[language]}
                </span>
            </div>
        </div>
    </Link>
}
