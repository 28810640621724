const loginPageStrings = {
    text: {
        "pl-PL": "Zaloguj się",
        "uk-UA": "Авторизуватися"
    },
    subText: {
        "pl-PL": "Po zalogowaniu będziesz mógł zapisać swoją ścieżkę zwiedzania",
        "uk-UA": "Після входу ви зможете зберегти свій оглядовий маршрут"
    },
    validationMessage: {
        "pl-PL": "To pole jest wymagane",
        "uk-UA": "Це поле є обов'язковим"
    },
    emailValidationMessage:{
        "pl-PL": "Nieprawidłowy adres email",
        "uk-UA": "Неправильна адреса електронної пошти"
    },
    emailPlaceholder: {
        "pl-PL": "Adres e-mail",
        "uk-UA": "Електронна пошта"
    },
    passwordPlaceholder: {
        "pl-PL": "Hasło",
        "uk-UA": "Пароль"
    },
    forgotPassword: {
        "pl-PL": "Nie pamiętasz hasła?",
        "uk-UA": "Ви не пам'ятаєте пароль?"
    },
    submitButtonTitle: {
        "pl-PL": "Zaloguj się",
        "uk-UA": "Авторизуватися"
    },
    registerLabel: {
        "pl-PL": "Nie masz konta? Zarejestruj się",
        "uk-UA": "У вас немає облікового запису? зареєструватися"
    },
    logout: {
        "pl-PL": "Wyloguj się",
        "uk-UA": "Вийти"
    },
    loggedInAs: {
        "pl-PL": "zalogowany jako:",
        "uk-UA": "ви увійшли як:"
    }
};

export default loginPageStrings;
