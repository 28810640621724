import {Select} from "antd";
import React from "react";
import styles from './languageSelector.module.less';
import {useSelector} from "react-redux";
import {getGlobalLanguage} from "../../../redux/languageSlice";
import PL from "../../../assets/flags/PL.png";
import UA from "../../../assets/flags/UA.png";

const {Option} = Select;

export type Language = string;

export default function LanguageSelector(props: LanguageSelectorProps) {
    const defaultLanguage = useSelector(getGlobalLanguage);

    return <Select
        className={styles.languageSelector}
        key={defaultLanguage}
        bordered={props.bordered ?? true}
        disabled={props.loading || props.disabled}
        loading={props.loading}
        defaultValue={defaultLanguage || ""}
        onSelect={props.onSelect}
    >
        <Option value={"pl-PL"}>
            <img src={PL} alt={"pl"}/>
        </Option>
        <Option value={"uk-UA"}>
            <img src={UA} alt={"ua"}/>
        </Option>
    </Select>;
}

interface LanguageSelectorProps {
    onSelect: (value: string) => void,
    disabled?: boolean,
    loading?: boolean,
    bordered?: boolean
}
