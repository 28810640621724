import Sider from "antd/lib/layout/Sider";
import {ReactComponent as Close} from "../../../assets/close.svg";
import {CnkLogo} from "../header/header";
import {Button, ConfigProvider, Divider, Modal, ThemeConfig} from "antd";
import styles from './mapSider.module.less';
import {useDispatch, useSelector} from "react-redux";
import {getGlobalLanguage, setGlobalLanguage} from "../../../redux/languageSlice";
import {ReactComponent as PlFlag} from "../../../assets/flags/flag-pl.svg";
import {ReactComponent as UaFlag} from "../../../assets/flags/flag-ua.svg";
import {ReactComponent as Arrow} from "../../../assets/arrow.svg";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import classNames from "classnames";
import {Link, useNavigate} from "react-router-dom";
import mapSiderStrings from "./mapSider.strings";
import {getCurrentUser} from "../../../redux/applicationUserSlice";
import {RoutingPaths} from "../../routing/routingContainer";
import {useAuth} from "oidc-react";
import {LoginOutlined, LogoutOutlined} from "@ant-design/icons";
import {AsyncButton} from "digimuth-components/dist";
import CustomCheckbox from "../../../components/CustomCheckbox/customCheckbox";
import {getContrast, getFacilities, setContrast, setFacilities} from "../../../redux/settingsSlice";
import loginPageStrings from "../../loginPage/loginPage.strings";
import useCurrentUserRoles from "../../../hooks/useCurrentUserRoles";
import { ApplicationUserRole } from "../../../api";

export default function MapSider(props: MapSiderProps) {
    const auth = useAuth();
    const navigate = useNavigate();
    const language = useSelector(getGlobalLanguage);
    const currentUser = useSelector(getCurrentUser);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const [breakpointHit, setBreakpointHit] = useState(false);
    const siderRef = useRef<HTMLDivElement | null>(null);

    const highContrastValue = useSelector(getContrast);
    const facilitiesValue = useSelector(getFacilities);
    const [localContrast, setLocalContrast] = useState(highContrastValue);
    const [localFacilities, setLocalFacilities] = useState(facilitiesValue);

    useLayoutEffect(() => {
        const target = siderRef.current;
        if (!target) return
        const disablePinchZoom = (e: any) => {
            if (e.touches.length > 1) {
                e.preventDefault()
            }
        }
        target.addEventListener("touchmove", disablePinchZoom, {passive: false})
        return () => {
            target.removeEventListener("touchmove", disablePinchZoom)
        }
    }, [])

    useEffect(() => {
        if (breakpointHit) {
            const handleClickOutside = (event: any) => {
                if (siderRef.current && !siderRef.current.contains(event.target) && !props.siderCollapsed) {
                    props.setSiderCollapsed && props.setSiderCollapsed(true);
                }
            };
            document.addEventListener('click', handleClickOutside, true);

            return () => {
                document.removeEventListener('click', handleClickOutside, true);
            };
        }
    }, [props.setSiderCollapsed, props.siderCollapsed]);

    const onNavigation = () => {
        if (breakpointHit) {
            props.setSiderCollapsed(true);
        }
    }

    const onLogoutPressed = async () => {
        await auth.signOut();
        await auth.signOutRedirect();
        navigate(RoutingPaths.homePage.route);
    }

    const onLoginPressed = async () => {
        await auth.userManager.signinSilent().catch(() =>
            auth.signIn({state: {targetPath: RoutingPaths.homePage.route}}));
    }

    const goToSettings = async () => {
        if (breakpointHit) {
            props.setSiderCollapsed(true);
        }
        navigate(RoutingPaths.changePassword.route);
    }

    const saveChanges = () => {
        dispatch(setContrast(localContrast));
        dispatch(setFacilities(localFacilities));
    }

    const resetChanges = () => {
        setLocalContrast(highContrastValue);
        setLocalFacilities(facilitiesValue);
    };

    const name = currentUser?.userName ?? "";
    const nameStyles = {
        fontSize: name.length > 22 ? "16px" : "20px",
        wordWrap: name.length > 30 ? "break-word" : "initial"
    } as any;

    return <div className={styles.sider}>
        <Sider
            breakpoint="lg"
            ref={siderRef}
            collapsed={props.siderCollapsed}
            trigger={null}
            width={310}
            onBreakpoint={b => {
                setBreakpointHit(b);
                props.setSiderCollapsed(b);
            }}
            collapsedWidth="0">
            <div className={styles.siderHeader}>
                <CnkLogo onClick={onNavigation}/>
                <Button type="text" icon={<Close/>} onClick={() => props.setSiderCollapsed(true)}/>
            </div>
            <LanguagePicker
                languageLabel={mapSiderStrings.languageLabel[language]}
                selectedLanguage={mapSiderStrings.selectedLanguage[language]}
            />
            <NavigationMenu
                setIsModalOpen={setIsModalOpen}
                onPress={onNavigation}
                map={mapSiderStrings.map[language]}
                stands={mapSiderStrings.stands[language]}
                about={mapSiderStrings.about[language]}
                myList={mapSiderStrings.myList[language]}
                mapSettings={mapSiderStrings.mapSettings[language]}
            />

            <div style={{padding: "30px 20px", marginTop: "auto", borderTop: "1px solid #D3D3D3"}}>
                {auth.userData ?
                    <>
                        <div className={styles.loginLabel}>
                            {loginPageStrings.loggedInAs[language]}
                        </div>
                        <Link to={RoutingPaths.changePassword.route} className={styles.loginName} style={nameStyles}
                              onClick={goToSettings}>
                            {
                                name.length > 30
                                    ? <>
                                        <div>{name.split('@')[0]}</div>
                                        <div>@{name.split('@')[1]}</div>
                                    </>
                                    : <>
                                        <div>{name}</div>
                                    </>
                            }
                        </Link>

                        <LogoutOutlined/>
                        <AsyncButton type="text" onClick={onLogoutPressed}>
                            {loginPageStrings.logout[language]}
                        </AsyncButton>
                    </>
                    :
                    <div>
                        <LoginOutlined/>
                        <AsyncButton type="text" onClick={onLoginPressed}>
                            {loginPageStrings.text[language]}
                        </AsyncButton>
                    </div>
                }
            </div>
            <Modal
                title={mapSiderStrings.mapSettings[language]}
                open={isModalOpen}
                onOk={() => {
                    setIsModalOpen(false);
                    saveChanges();
                    onNavigation();
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                    resetChanges();
                    onNavigation();
                }}
                cancelText={mapSiderStrings.cancel[language]}
                okText={"Ok"}
            >
                <div style={{display: "flex", flexDirection: "column", gap: "15px"}}>
                    <CustomCheckbox wrapperClassName={styles.checkboxWrapper}
                                    text={mapSiderStrings.highContrast[language]}
                                    defaultValue={highContrastValue} onChange={setLocalContrast}
                                    textStyles={styles.checkboxText} value={localContrast}/>
                    <CustomCheckbox wrapperClassName={styles.checkboxWrapper}
                                    text={mapSiderStrings.facilities[language]} defaultValue={facilitiesValue}
                                    onChange={setLocalFacilities} textStyles={styles.checkboxText}
                                    value={localFacilities}/>
                </div>
            </Modal>
        </Sider>
    </div>
}

function NavigationMenu(props: {
    map: string,
    stands: string,
    about: string,
    myList: string,
    mapSettings: string,
    onPress: () => any,
    setIsModalOpen: any
}) {
    const language = useSelector(getGlobalLanguage);
    const userRoles = useCurrentUserRoles();

    return <div className={styles.navigationMenu}>
        <Link to="/" onClick={() => props.onPress()}>
            <Button type="text">
                {props.map}
            </Button>
        </Link>
        <Link to="/stands" onClick={() => props.onPress()}>
            <Button type="text">
                {props.stands}
            </Button>
        </Link>
        <Button type="text"
                onClick={() => window.open("https://pikniknaukowy.pl" + (language == "uk-UA" ? "/ua" : "/"), "_blank")}>
            {props.about}
        </Button>
        <Link to="/favourites" onClick={() => props.onPress()}>
            <Button type="text">
                {props.myList}
            </Button>
        </Link>
        <Button type="text" onClick={() => {
            props.setIsModalOpen(true);
        }}>
            {props.mapSettings}
        </Button>
        
        {userRoles?.includes(ApplicationUserRole.Admin) &&
        <>
        <Divider />
        <Link to="/cms" onClick={() => props.onPress()}>
            <Button type="text">
                Panel zarządzania
            </Button>
        </Link>
        </>
        }
    </div>
}

const languages = [{
    key: "pl-PL",
    name: "polski",
    flag: <PlFlag/>
}, {
    key: "uk-UA",
    name: "українська",
    flag: <UaFlag/>
}];

function LanguagePicker(props: { languageLabel: string, selectedLanguage: string }) {
    const [expanded, setExpanded] = useState(false);
    const language = useSelector(getGlobalLanguage);
    const dispatch = useDispatch();

    const currentLanguage = languages.find(l => l.key === language);
    const remainingLanguages = languages.filter(l => l !== currentLanguage);

    const selectLanguage = (key: string) => {
        dispatch(setGlobalLanguage(key));
        setExpanded(false);
    }

    return <div className={styles.languagePicker}>
        <Button type="text" className={classNames(styles.language, styles.currentLanguage)}
                onClick={() => setExpanded(!expanded)}>
            <div className={styles.flagNameWrapper}>
                {currentLanguage?.flag}
                <div className={styles.nameWrapper}>
                    <span className={styles.nameLabel}>{props.languageLabel}</span>
                    <span className={styles.name}>
                        {currentLanguage?.name}
                        <span className={styles.selected}>{props.selectedLanguage}</span>
                    </span>
                </div>
            </div>
            <Arrow style={{rotate: expanded ? "270deg" : "90deg"}}/>
        </Button>

        <div className={styles.remainingLanguages} style={{maxHeight: expanded ? 50 * remainingLanguages.length : 0}}>
            {remainingLanguages.map(l => <Button type="text" className={styles.language}
                                                 onClick={() => selectLanguage(l.key)} key={l.key}>
                <div className={styles.flagNameWrapper}>
                    {l.flag}
                    <div className={styles.nameWrapper}>
              <span className={styles.name}>
                {l.name}
              </span>
                    </div>
                </div>
            </Button>)}
        </div>
    </div>
}

interface MapSiderProps {
    siderCollapsed: boolean,
    setSiderCollapsed: (v: boolean) => void
}
