const mapSiderStrings = {
    languageLabel: {
        "pl-PL": "Język",
        "uk-UA": "Язик"
    },
    selectedLanguage: {
        "pl-PL": "wybrano",
        "uk-UA": "вибрано"
    },
    map: {
        "pl-PL": "Mapa Pikniku",
        "uk-UA": "Карта пікніка"
    },
    stands: {
        "pl-PL": "Lista stref i stanowisk",
        "uk-UA": "Перелік зон і стендів"
    },
    about: {
        "pl-PL": "O programie",
        "uk-UA": "Про програму"
    },
    myList: {
        "pl-PL": "Moja lista",
        "uk-UA": "Мій маршрут"
    },
    mapSettings: {
        "pl-PL": "Ustawienia mapy",
        "uk-UA": "Налаштування карти"
    },
    highContrast:{
        "pl-PL": "Wysoki kontrast",
        "uk-UA": "Високий контраст"
    },
    facilities:{
        "pl-PL": "Ułatwienia dla osób z niepełnosprawnościami",
        "uk-UA": "Полегшення для людей з обмеженими можливостями"
    },
    cancel:{
        "pl-PL": "Anuluj",
        "uk-UA": "Скасувати"
    }
};

export default mapSiderStrings
